$background: white;
$noir: black;
$margin: 150px;
$margin-mobile: 55px;
$gap: 15px;
$margin-top: 34px;
$margin-top-mobile: 20px;
$gap-mobile: 4px;
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}
html,
body {
  width: 100%;
  height: 100%;
}

body {
  // font-family: "Averta W01 Regular", sans-serif;
  min-height: 100vh;
  color: $noir;
  margin: 0px;
  padding: $margin-top;
  font-weight: 100;
  padding-right: $margin;
  padding-left: $margin;
  em {
    font-style: normal;
  }

  @media only screen and (max-width: 800px) {
    // grid-template-columns: 1fr;
    padding: 0px;
    padding-top: $margin-top-mobile;
    padding-left: 18px;

    em {
      font-style: normal;
    }
  }

  a {
    color: $noir;
    &:hover {
      color: $noir;
    }
    &:visited {
      color: $noir;
    }
    &:active {
      color: $noir;
    }
  }

  // #content {
  //   height: 100%;
  //   #mainGallery {
  //     height: 100%;
  //     .mainColumn {
  //       display: flex;
  //       height: 100%;
  //       flex-direction: column;
  //       .horizontal_viewer {
  //         grid-template-columns: 1fr 1fr 1fr;
  //         grid-gap: 10px;
  //         margin: 10px;
  //         margin-top: 0px;
  //         display: grid;
  //         &.text {
  //           grid-template-columns: 1fr;
  //           background-color: $background;
  //           p {
  //             font-size: 4vw;
  //             font-family: "Averta W01 Bold Italic", sans-serif;
  //             line-height: 3.8vw;
  //           }
  //         }
  //         .image {
  //           // aspect-ratio: 1/1;
  //           aspect-ratio: 0.666667 / 1;
  //           display: block;
  //           position: relative;
  //           background-color: $background;
  //           &.doubleColumn {
  //             grid-column: span 2;
  //           }
  //           &.tripleColumn {
  //             grid-column: span 3;
  //           }
  //           .ratioBox {
  //             background-position: 50%;
  //             background-repeat: no-repeat;
  //             background-size: cover;
  //             width: 100%;
  //             height: 100%;
  //             position: relative;
  //           }
  //           ul {
  //             position: relative;
  //             bottom: 0px;
  //             list-style: none;
  //             display: flex;
  //             flex-wrap: wrap;
  //             &::before {
  //               padding-top: 3px;
  //               font-size: 1.5em;
  //               content: "●";
  //             }

  //             li {
  //               margin: 5px;
  //               padding-top: 5px;
  //               font-size: 1em;
  //               font-weight: 300;
  //               text-transform: uppercase;
  //               cursor: pointer;
  //               &:not(:first-child) {
  //                 &::before {
  //                   content: "/ ";
  //                 }
  //               }
  //               &:hover {
  //                 color: $noir;
  //               }
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  // }

  #side_menu {
    font-family: "Averta W01 Regular", sans-serif;
    position: fixed;
    top: 0px;
    right: 0px;
    width: $margin;
    height: 100%;
    @media only screen and (max-width: 800px) {
      width: calc($margin-mobile + 0px);
    }
    .menu_button {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      background-color: $background;
      z-index: 11;
      position: absolute;
      cursor: pointer;
      .icon {
        display: flex;
        writing-mode: vertical-rl;
        text-orientation: upright;

        -webkit-writing-mode: vertical-rl;
        -webkit-text-orientation: upright;

        cursor: pointer;
        margin-top: calc($margin-top - 5px);
        font-size: 27px;
        letter-spacing: -6px;
        font-weight: 400;
        text-rendering: geometricPrecision;
        @media only screen and (max-width: 800px) {
          margin-top: calc($margin-top-mobile - 5px);
          font-size: 23px;
        }
        .letter {
          display: inline-block;
          transform: translateX(-4.5px);
          &.offset {
            transform: translateX(-4.8px);
          }
          @media only screen and (max-width: 800px) {
            transform: unset;
            &.offset {
              transform: translateX(-0.4px);
            }
          }
        }
        .dot {
          display: inline-block;
          margin-top: 8px;
          margin-bottom: 8px;
          font-size: 35px;
          height: 34px;
          @media only screen and (max-width: 800px) {
            font-size: 23px;

            @supports (-webkit-touch-callout: none) {
              /* CSS specific to iOS devices */
              margin-top: 16px;
              margin-bottom: 4px;
            }
          }
        }
        &.hidden {
          .letter {
            // display: none;
            opacity: 0;
          }
        }
      }
      .vertical_tag {
        writing-mode: vertical-lr;
        text-transform: uppercase;
        align-items: center;
        padding-bottom: 10px;
        font-size: 17.5px; /*0.9em;*/
        font-weight: 400;
        display: flex;
        transform: rotate(180deg);
        letter-spacing: 0.2px;
        @media only screen and (max-width: 800px) {
          font-size: 13px;
          padding-bottom: 0px;
        }
      }
    }

    .list {
      box-sizing: border-box;
      text-transform: uppercase;
      z-index: 10;
      background-color: $background;
      border-left: 1px solid #efefef;
      min-width: 200px;
      height: 100vh;
      padding: 20px;
      padding-top: 30px;
      padding-left: 60px;
      font-weight: 400;
      list-style: none;
      transition: transform 0.3s ease-out;
      position: absolute;
      top: 0;
      right: $margin;
      transform: translate(100%);
      @media only screen and (max-width: 800px) {
        padding-top: calc($margin-top-mobile - 0px);
        right: calc($margin-mobile - $gap-mobile);
        padding-left: 15px;
        min-width: calc(100% - $margin-mobile + $gap-mobile);
      }
      li {
        cursor: pointer;
        margin-bottom: 20px;
        text-wrap: nowrap;
        white-space: nowrap;
        font-family: "Averta W01 Regular", sans-serif;
        font-size: 20px;
        a {
          // pointer-events: none;
          text-decoration: unset;
        }
        &:hover {
          color: $noir;
        }
        &.separator {
          cursor: unset;
          color: unset;
          margin-bottom: 3px;
          font-size: 30px;
          @media only screen and (max-width: 800px) {
            font-size: 23px;
          }
        }
        @media only screen and (max-width: 800px) {
          margin-bottom: 18px;
          font-size: 13px;
        }
      }
    }
  }
  &.menu--open {
    #side_menu {
      .list {
        transform: translate(0%);
      }
    }
  }

  .hidden_list {
    display: none;
  }
}
